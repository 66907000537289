import React from 'react';
import { ListItem, Text } from '../../containers/SaasModern/Terms/terms.style';

export const ContentComponent = () => {
  return (
    <>
      <ListItem>Content</ListItem>
      <Text>
        <span>
          Content found on or through this Service are the property of BLUEFROG
          VENTURES or used with permission. You may not distribute, modify,
          transmit, reuse, download, repost, copy, or use said Content, whether
          in whole or in part, for commercial purposes or for personal gain,
          without express advance written permission from us.
        </span>
        <br />
        <br />
      </Text>
    </>
  );
};
