import styled from 'styled-components';
import AnchorLink from 'react-anchor-link-smooth-scroll';

export const StyledA = styled.a`
  color: ${props =>
    props.show && props.show !== 'home' ? '#000000 !important' : ''};
`;

export const StyledAnchorLink = styled(AnchorLink)`
  color: ${props =>
    props.show && props.show !== 'home' ? '#000000 !important' : ''};
`;
