import React from 'react';
import { ListItem, Text } from '../../containers/SaasModern/Terms/terms.style';

export const WaiverComponent = () => {
  return (
    <>
      <ListItem>Waiver And Severability </ListItem>
      <Text>
        <span>
          No waiver by Company of any term or condition set forth in Terms shall
          be deemed a further or continuing waiver of such term or condition or
          a waiver of any other term or condition, and any failure of Company to
          assert a right or provision under Terms shall not constitute a waiver
          of such right or provision.
        </span>
        <br />
        <br />
        <span>
          If any provision of Terms is held by a court or other tribunal of
          competent jurisdiction to be invalid, illegal or unenforceable for any
          reason, such provision shall be eliminated or limited to the minimum
          extent such that the remaining provisions of Terms will continue in
          full force and effect.
        </span>
        <br />
        <br />
      </Text>
    </>
  );
};
