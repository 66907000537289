import styled from 'styled-components';

export const PolicyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2% 4%;
  margin-top: 70px;
`;

export const Title = styled.h1`
  display: flex;
  justify-content: center;
`;

export const Ol = styled.ol``;

export const DateItem = styled.h3`
  padding-left: 20px;
`;

export const ListItem = styled.li`
  font-size: 1.17em;
  list-style-type: decimal;
  font-weight: bold;
  text-decoration: underline;
`;

export const Text = styled.h3`
  font-weight: initial;
`;

export const BoldSpan = styled.span`
  font-weight: bold;
`;

export const ListItemTwo = styled.li`
  list-style-type: lower-alpha;
`;

export const OlTwo = styled.ol`
  padding-left: 20px;
`;

export const ListItemThree = styled(ListItemTwo)`
  list-style-type: lower-roman;
`;
