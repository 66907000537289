import React from 'react';
import {
  ListItem,
  Text,
  BoldSpan,
} from '../../containers/SaasModern/Privacy/privacy.style';

export const ChildrensComponent = () => {
  return (
    <>
      <ListItem>Children's Privacy</ListItem>
      <Text>
        <span>
          Our Services are not intended for use by children under the age of 13
          (<BoldSpan>“Children”</BoldSpan>).
        </span>
        <br />
        <br />
        <span>
          We do not knowingly collect personally identifiable information from
          Children under 13. If you become aware that a Child has provided us
          with Personal Data, please contact us. If we become aware that we have
          collected Personal Data from Children without verification of parental
          consent, we take steps to remove that information from our servers.
        </span>
        <br />
        <br />
      </Text>
    </>
  );
};
