import React from 'react';
import {
  ListItem,
  Text,
  ListItemTwo,
  OlTwo,
} from '../../containers/SaasModern/Privacy/privacy.style';

export const UseComponent = () => {
  return (
    <>
      <ListItem>Use of Data</ListItem>
      <Text>
        <span>
          Bluefrog Ventures uses the collected data for various purposes:
        </span>
        <br />
        <br />
        <OlTwo>
          <ListItemTwo>to provide and maintain our Service;</ListItemTwo>
          <br />
          <ListItemTwo>to notify you about changes to our Service;</ListItemTwo>
          <br />
          <ListItemTwo>
            to allow you to participate in interactive features of our Service
            when you choose to do so;
          </ListItemTwo>
          <br />
          <ListItemTwo>to provide customer support;</ListItemTwo>
          <br />
          <ListItemTwo>
            to gather analysis or valuable information so that we can improve
            our Service;
          </ListItemTwo>
          <br />
          <ListItemTwo>to monitor the usage of our Service;</ListItemTwo>
          <br />
          <ListItemTwo>
            to detect, prevent and address technical issues;
          </ListItemTwo>
          <br />
          <ListItemTwo>
            to fulfill any other purpose for which you provide it;
          </ListItemTwo>
          <br />
          <ListItemTwo>
            to carry out our obligations and enforce our rights arising from any
            contracts entered into between you and us, including for billing and
            collection;
          </ListItemTwo>
          <br />
          <ListItemTwo>
            to provide you with notices about your account and/or subscription,
            including expiration and renewal notices, email-instructions, etc.;
          </ListItemTwo>
          <br />
          <ListItemTwo>
            to provide you with news, special offers and general information
            about other goods, services and events which we offer that are
            similar to those that you have already purchased or enquired about
            unless you have opted not to receive such information;
          </ListItemTwo>
          <br />
          <ListItemTwo>
            in any other way we may describe when you provide the information;
          </ListItemTwo>
          <br />
          <ListItemTwo>for any other purpose with your consent.</ListItemTwo>
          <br />
        </OlTwo>
      </Text>
    </>
  );
};
