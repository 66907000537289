import React from 'react';
import {
  ListItem,
  Text,
  BoldSpan,
  OlTwo,
  ListItemTwo,
} from '../../containers/SaasModern/Privacy/privacy.style';

export const TypesComponent = () => {
  return (
    <>
      <ListItem>Types of Data Collected</ListItem>
      <Text>
        <BoldSpan>Personal Data</BoldSpan>
        <br />
        <span>
          While using our Service, we may ask you to provide us with certain
          personally identifiable information that can be used to contact or
          identify you (“Personal Data”). Personally identifiable information
          may include, but is not limited to:
        </span>
        <br />
        <br />
        <OlTwo>
          <ListItemTwo>Email address</ListItemTwo>
          <br />
          <ListItemTwo>First name and last name</ListItemTwo>
          <br />
          <ListItemTwo>Phone number</ListItemTwo>
          <br />
          <ListItemTwo>
            Address, State, Province, ZIP/Postal code, City
          </ListItemTwo>
          <br />
          <ListItemTwo>Cookies and Usage Data</ListItemTwo>
        </OlTwo>
        <span>
          We may use your Personal Data to contact you with newsletters,
          marketing or promotional materials and other information that may be
          of interest to you. You may opt out of receiving any, or all, of these
          communications from us by following the unsubscribe link or by
          emailing at support@estackk.com.
        </span>
        <br />
        <br />
        <BoldSpan>Usage Data</BoldSpan>
        <br />
        <span>
          We may also collect information that your browser sends whenever you
          visit our Service or when you access Service by or through a mobile
          device (<BoldSpan>“Usage Data”</BoldSpan>).
        </span>
        <br />
        <br />
        <span>
          This Usage Data may include information such as your computer's
          Internet Protocol address (e.g. IP address), browser type, browser
          version, the pages of our Service that you visit, the time and date of
          your visit, the time spent on those pages, unique device identifiers
          and other diagnostic data.
        </span>
        <br />
        <br />
        <span>
          When you access Service with a mobile device, this Usage Data may
          include information such as the type of mobile device you use, your
          mobile device unique ID, the IP address of your mobile device, your
          mobile operating system, the type of mobile Internet browser you use,
          unique device identifiers and other diagnostic data.
        </span>
        <br />
        <br />
        <BoldSpan>Location Data</BoldSpan>
        <br />
        <span>
          We may use and store information about your location if you give us
          permission to do so (<BoldSpan>“Location Data”</BoldSpan>). We use
          this data to provide features of our Service, to improve and customize
          our Service.
        </span>
        <br />
        <br />
        <span>
          You can enable or disable location services when you use our Service
          at any time by way of your device settings.
        </span>
        <br />
        <br />
        <BoldSpan>Tracking Cookies Data</BoldSpan>
        <br />
        <span>
          We use cookies and similar tracking technologies to track the activity
          on our Service and we hold certain information.
        </span>
        <br />
        <br />
        <span>
          You can enable or disable location services when you use our Service
          at any time by way of your device settings.
        </span>
        <span>
          Cookies are files with a small amount of data which may include an
          anonymous unique identifier. Cookies are sent to your browser from a
          website and stored on your device. Other tracking technologies are
          also used such as beacons, tags and scripts to collect and track
          information and to improve and analyze our Service.
        </span>
        <br />
        <br />
        <span>
          You can instruct your browser to refuse all cookies or to indicate
          when a cookie is being sent. However, if you do not accept cookies,
          you may not be able to use some portions of our Service.
        </span>
        <br />
        <br />
        <span>Examples of Cookies we use:</span>
        <OlTwo>
          <ListItemTwo>
            <BoldSpan>Session Cookies:</BoldSpan>
            <span>We use Session Cookies to operate our Service.</span>
          </ListItemTwo>
          <br />
          <ListItemTwo>
            <BoldSpan>Preference Cookies:</BoldSpan>
            <span>
              We use Preference Cookies to remember your preferences and various
              settings.
            </span>
          </ListItemTwo>
          <br />
          <ListItemTwo>
            <BoldSpan>Security Cookies:</BoldSpan>
            <span>We use Security Cookies for security purposes.</span>
          </ListItemTwo>
          <br />
          <ListItemTwo>
            <BoldSpan>Advertising Cookies:</BoldSpan>
            <span>
              Advertising Cookies are used to serve you with advertisements that
              may be relevant to you and your interests.
            </span>
          </ListItemTwo>
        </OlTwo>
      </Text>
    </>
  );
};
