import React from 'react';
import { ListItem, Text } from '../../containers/SaasModern/Terms/terms.style';

export const LimitationComponent = () => {
  return (
    <>
      <ListItem>Limitation Of Liability </ListItem>
      <Text>
        <span>
          EXCEPT AS PROHIBITED BY LAW, YOU WILL HOLD US AND OUR OFFICERS,
          DIRECTORS, EMPLOYEES, AND AGENTS HARMLESS FOR ANY INDIRECT, PUNITIVE,
          SPECIAL, INCIDENTAL, OR CONSEQUENTIAL DAMAGE, HOWEVER IT ARISES
          (INCLUDING ATTORNEYS' FEES AND ALL RELATED COSTS AND EXPENSES OF
          LITIGATION AND ARBITRATION, OR AT TRIAL OR ON APPEAL, IF ANY, WHETHER
          OR NOT LITIGATION OR ARBITRATION IS INSTITUTED), WHETHER IN AN ACTION
          OF CONTRACT, NEGLIGENCE, OR OTHER TORTIOUS ACTION, OR ARISING OUT OF
          OR IN CONNECTION WITH THIS AGREEMENT, INCLUDING WITHOUT LIMITATION ANY
          CLAIM FOR PERSONAL INJURY OR PROPERTY DAMAGE, ARISING FROM THIS
          AGREEMENT AND ANY VIOLATION BY YOU OF ANY FEDERAL, STATE, OR LOCAL
          LAWS, STATUTES, RULES, OR REGULATIONS, EVEN IF COMPANY HAS BEEN
          PREVIOUSLY ADVISED OF THE POSSIBILITY OF SUCH DAMAGE. EXCEPT AS
          PROHIBITED BY LAW, IF THERE IS LIABILITY FOUND ON THE PART OF COMPANY,
          IT WILL BE LIMITED TO THE AMOUNT PAID FOR THE PRODUCTS AND/OR
          SERVICES, AND UNDER NO CIRCUMSTANCES WILL THERE BE CONSEQUENTIAL OR
          PUNITIVE DAMAGES. SOME STATES DO NOT ALLOW THE EXCLUSION OR LIMITATION
          OF PUNITIVE, INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE PRIOR
          LIMITATION OR EXCLUSION MAY NOT APPLY TO YOU.
        </span>
        <br />
        <br />
      </Text>
    </>
  );
};
