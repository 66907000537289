import React from 'react';
import {
  ListItem,
  ListItemTwo,
  OlTwo,
  Text,
} from '../../containers/SaasModern/Privacy/privacy.style';

export const ProtectionGeneralComponent = () => {
  return (
    <>
      <ListItem>
        Your Data Protection Rights Under General Data Protection Regulation
        (GDPR)
      </ListItem>
      <Text>
        <span>
          If you are a resident of the European Union (EU) and European Economic
          Area (EEA), you have certain data protection rights, covered by GDPR.
          – See more at https://eur-lex.europa.eu/eli/reg/2016/679/oj
        </span>
        <br />
        <br />
        <span>
          We aim to take reasonable steps to allow you to correct, amend,
          delete, or limit the use of your Personal Data.
        </span>
        <br />
        <br />
        <span>
          If you wish to be informed what Personal Data we hold about you and if
          you want it to be removed from our systems, please email us at
          support@estackk.com.
        </span>
        <br />
        <br />
        <span>
          In certain circumstances, you have the following data protection
          rights:
        </span>
        <br />
        <br />
        <OlTwo>
          <ListItemTwo>
            the right to access, update or to delete the information we have on
            you;
          </ListItemTwo>
          <br />
          <ListItemTwo>
            the right of rectification. You have the right to have your
            information rectified if that information is inaccurate or
            incomplete;
          </ListItemTwo>
          <br />
          <ListItemTwo>
            the right to object. You have the right to object to our processing
            of your Personal Data;
          </ListItemTwo>
          <br />
          <ListItemTwo>
            the right of restriction. You have the right to request that we
            restrict the processing of your personal information;
          </ListItemTwo>
          <br />
          <ListItemTwo>
            the right to data portability. You have the right to be provided
            with a copy of your Personal Data in a structured, machine-readable
            and commonly used format;
          </ListItemTwo>
          <br />
          <ListItemTwo>
            the right to withdraw consent. You also have the right to withdraw
            your consent at any time where we rely on your consent to process
            your personal information;
          </ListItemTwo>
          <br />
        </OlTwo>
        <span>
          Please note that we may ask you to verify your identity before
          responding to such requests. Please note, we may not able to provide
          Service without some necessary data.
        </span>
        <br />
        <br />
        <span>
          You have the right to complain to a Data Protection Authority about
          our collection and use of your Personal Data. For more information,
          please contact your local data protection authority in the European
          Economic Area (EEA).
        </span>
        <br />
        <br />
      </Text>
    </>
  );
};
