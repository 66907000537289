import React from 'react';
import PropTypes from 'prop-types';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Image from 'common/src/components/Image';
import Container from 'common/src/components/UI/Container';
import {
  PartnerSectionWrapper,
  QrCodeWrapper,
  QrCodeBlock,
  ImageWrapper,
  PlayImage,
} from './partner.style';
import Partner from 'common/src/assets/image/saasModern/partner.png';
import PlayBtn from 'common/src/assets/image/saasModern/play-button.png';
import Google from 'common/src/assets/image/saasModern/png-clipart-google-play-logo-google-play-computer-icons-app-store-google-text-logo.png';
import AppStore from 'common/src/assets/image/saasModern/appstore.png';

import * as QRCode from 'qrcode.react';
import { playMarketLink, appStoreLink } from '../../../helpers';

const PartnerSection = ({
  row,
  col,
  title,
  description,
  button,
  textArea,
  imageArea,
}) => {
  const [imgToVideoFlag, setImgToVideoFlag] = React.useState(false);
  const handlePlayClick = () => {
    setImgToVideoFlag(true);
  };
  return (
    <PartnerSectionWrapper>
      <Container>
        <Box {...row}>
          <Box {...col} {...imageArea}>
            {!imgToVideoFlag && (
              <div style={{ position: 'relative' }}>
                <Image src={Partner} alt="Domain Image" />
                <PlayImage
                  src={PlayBtn}
                  alt="Play Button"
                  style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                  }}
                  onClick={handlePlayClick}
                />
              </div>
            )}
            {imgToVideoFlag && (
              <iframe
                src="https://www.youtube.com/embed/NGMgOLJXVRg?autoplay=1"
                title="Video Title"
                width="630px"
                height="560px"
                allow="autoplay;"
                frameBorder="0"
                webkitallowfullscreen="true"
                mozallowfullscreen="true"
                allowFullScreen
                autoplay
              />
            )}
          </Box>
          <Box {...col} {...textArea}>
            <Heading
              {...title}
              content="Loyalty app to earn & redeem all rewards in one place"
            />
            <Text
              {...description}
              content="Register your VISA/MASTERCARD or flash your QR code to earn points. Redeem points, coupons & gift cards with your favourite merchants online and offline."
            />
            <QrCodeWrapper>
              <QrCodeBlock>
                <QRCode value={playMarketLink} />
                <a href={playMarketLink}>
                  <Image src={Google} width="128px" alt="Google Image" />
                </a>
              </QrCodeBlock>
              <QrCodeBlock>
                <QRCode value={appStoreLink} />
                <a href={appStoreLink}>
                  <Image src={AppStore} width="128px" alt="AppStore Image" />
                </a>
              </QrCodeBlock>
            </QrCodeWrapper>
            {/* <Box>
              <a href="#1">
                <Button {...button} title="LEARN MORE" />
              </a>
            </Box> */}
          </Box>
        </Box>
      </Container>
    </PartnerSectionWrapper>
  );
};

PartnerSection.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
  title: PropTypes.object,
  description: PropTypes.object,
  button: PropTypes.object,
  textArea: PropTypes.object,
  imageArea: PropTypes.object,
};

PartnerSection.defaultProps = {
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
    alignItems: 'center',
  },
  imageAreaRow: {
    flexDirection: 'row-reverse',
  },
  col: {
    pr: '15px',
    pl: '15px',
  },
  textArea: {
    width: ['100%', '100%', '55%', '50%', '42%'],
  },
  imageArea: {
    width: ['100%', '100%', '45%', '50%', '58%'],
    mb: ['40px', '40px', '0', '0', '0'],
  },
  title: {
    fontSize: ['26px', '30px', '30px', '48px', '48px'],
    fontWeight: '400',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    mb: '15px',
    lineHeight: '1.25',
  },
  description: {
    fontSize: '16px',
    color: '#343d48cc',
    lineHeight: '1.75',
    mb: '33px',
  },
  button: {
    type: 'button',
    fontSize: '14px',
    fontWeight: '600',
    borderRadius: '4px',
    pl: '22px',
    pr: '22px',
    colors: 'secondaryWithBg',
    minWidth: '150px',
  },
};

export default PartnerSection;
