import React from 'react';
import {
  ListItem,
  Text,
  BoldSpan,
} from '../../containers/SaasModern/Privacy/privacy.style';

export const PaymentsComponent = () => {
  return (
    <>
      <ListItem>Payments</ListItem>
      <Text>
        <span>
          We may provide paid products and/or services within Service. In that
          case, we use third-party services for payment processing (e.g. payment
          processors).
        </span>
        <br />
        <br />
        <span>
          We will not store or collect your payment card details. That
          information is provided directly to our third-party payment processors
          whose use of your personal information is governed by their Privacy
          Policy. These payment processors adhere to the standards set by
          PCI-DSS as managed by the PCI Security Standards Council, which is a
          joint effort of brands like Visa, Mastercard, American Express and
          Discover. PCI-DSS requirements help ensure the secure handling of
          payment information.
        </span>
        <br />
        <br />
        <span>The payment processors we work with are:</span>
        <br />
        <br />
        <BoldSpan>PayPal or Braintree:</BoldSpan>
        <br />
        <span>
          Their Privacy Policy can be viewed
          at https://www.paypal.com/webapps/mpp/ua/privacy-full
        </span>
        <br />
        <br />
        <BoldSpan>Stripe:</BoldSpan>
        <br />
        <span>
          Their Privacy Policy can be viewed at: https://stripe.com/us/privacy
        </span>
        <br />
        <br />
      </Text>
    </>
  );
};
