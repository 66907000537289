import React from 'react';
import {
  ListItem,
  Text,
  BoldSpan,
} from '../../containers/SaasModern/Privacy/privacy.style';

export const BehavioralComponent = () => {
  return (
    <>
      <ListItem>Behavioral Remarketing</ListItem>
      <Text>
        <span>
          Bluefrog Ventures uses remarketing services to advertise on third
          party websites to you after you visited our Service. We and our
          third-party vendors use cookies to inform, optimise and serve ads
          based on your past visits to our Service.
        </span>
        <br />
        <br />
        <BoldSpan>Google Ads (AdWords)</BoldSpan>
        <br />
        <span>
          Google Ads (AdWords) remarketing service is provided by Google Inc.
        </span>
        <br />
        <br />
        <span>
          You can opt-out of Google Analytics for Display Advertising and
          customize the Google Display Network ads by visiting the Google Ads
          Settings page: http://www.google.com/settings/ads
        </span>
        <br />
        <br />
        <span>
          Google also recommends installing the Google Analytics Opt-out Browser
          Add-on – https://tools.google.com/dlpage/gaoptout – for your web
          browser. Google Analytics Opt-out Browser Add-on provides visitors
          with the ability to prevent their data from being collected and used
          by Google Analytics.
        </span>
        <br />
        <br />
        <span>
          For more information on the privacy practices of Google, please visit
          the Google Privacy Terms web page:
          https://policies.google.com/privacy?hl=en
        </span>
        <br />
        <br />
        <BoldSpan>Twitter</BoldSpan>
        <br />
        <span>Twitter remarketing service is provided by Twitter Inc.</span>
        <br />
        <br />
        <span>
          You can opt-out from Twitter's interest-based ads by following their
          instructions: https://support.twitter.com/articles/20170405
        </span>
        <br />
        <br />
        <span>
          You can learn more about the privacy practices and policies of Twitter
          by visiting their Privacy Policy page: https://twitter.com/privacy
        </span>
        <br />
        <br />
        <BoldSpan>Facebook</BoldSpan>
        <br />
        <span>Facebook remarketing service is provided by Facebook Inc.</span>
        <br />
        <br />
        <span>
          You can learn more about interest-based advertising from Facebook by
          visiting this page: https://www.facebook.com/help/164968693837950
        </span>
        <br />
        <br />
        <span>
          To opt-out from Facebook's interest-based ads, follow these
          instructions from Facebook:
          https://www.facebook.com/help/568137493302217
        </span>
        <br />
        <br />
        <span>
          Facebook adheres to the Self-Regulatory Principles for Online
          Behavioural Advertising established by the Digital Advertising
          Alliance. You can also opt-out from Facebook and other participating
          companies through the Digital Advertising Alliance in the USA
          http://www.aboutads.info/choices/, the Digital Advertising Alliance of
          Canada in Canada http://youradchoices.ca/ or the European Interactive
          Digital Advertising Alliance in Europe
          http://www.youronlinechoices.eu/, or opt-out using your mobile device
          settings.
        </span>
        <br />
        <br />
        <span>
          For more information on the privacy practices of Facebook, please
          visit Facebook's Data Policy:
          https://www.facebook.com/privacy/explanation
        </span>
        <br />
        <br />
      </Text>
    </>
  );
};
