import React from 'react';
import {
  ListItem,
  Text,
  BoldSpan,
} from '../../containers/SaasModern/Privacy/privacy.style';

export const IntroductionComponent = () => {
  return (
    <>
      <ListItem>Introduction</ListItem>
      <Text>
        <span>
          Welcome to  <BoldSpan>Bluefrog Ventures, Inc.</BoldSpan>.
        </span>
        <br />
        <br />
        <span>
          Bluefrog Ventures (“us”, “we”, or “our”) operates https://estackk.com
          and estackk & estackk POS mobile application (hereinafter referred to
          as <BoldSpan>“Service”</BoldSpan> ).
        </span>
        <br />
        <br />
        <span>
          Our Privacy Policy governs your visit to https://estackk.com and
          estackk & estackk POS mobile application, and explains how we collect,
          safeguard and disclose information that results from your use of our
          Service.
        </span>
        <br />
        <br />
        <span>
          We use your data to provide and improve Service. By using Service, you
          agree to the collection and use of information in accordance with this
          policy. Unless otherwise defined in this Privacy Policy, the terms
          used in this Privacy Policy have the same meanings as in our Terms and
          Conditions.
        </span>
        <br />
        <br />
        <span>
          Our Terms and Conditions (<BoldSpan>“Terms”</BoldSpan>) govern all use
          of our Service and together with the Privacy Policy constitutes your
          agreement with us (<BoldSpan>“agreement”</BoldSpan>).
        </span>
      </Text>
    </>
  );
};
