import React, { Fragment, useEffect, useState } from 'react';
import { ThemeProvider } from 'styled-components';
import Sticky from 'react-stickynode';
import { DrawerProvider } from '../../../common/src/contexts/DrawerContext';
import { saasModernTheme } from '../../../common/src/theme/saasModern';
import { ResetCSS } from '../../../common/src/assets/css/style';
import {
  GlobalStyle,
  ContentWrapper,
} from '../containers/SaasModern/sassModern.style';

import BannerSection from '../containers/SaasModern/Banner';
import Navbar from '../containers/SaasModern/Navbar';
import WorkingProcessSection from '../containers/SaasModern/WorkingProcess';
import PricingSection from '../containers/SaasModern/Pricing';
import PartnerSection from '../containers/SaasModern/Partner';
import FaqSection from '../containers/SaasModern/Faq';
import TrialSection from '../containers/SaasModern/Trial';
import InfoSection from '../containers/SaasModern/Info';
import FeatureSection from '../containers/SaasModern/Feature';
import UpdateScreen from '../containers/SaasModern/UpdateScreen';
import TestimonialSection from '../containers/SaasModern/Testimonial';
import Footer from '../containers/SaasModern/Footer';
import PrivacySection from '../containers/SaasModern/Privacy';
import TermsSection from '../containers/SaasModern/Terms';
import SEO from '../components/seo';

export default () => {
  const [show, setShow] = useState('home');

  const hash = typeof window !== 'undefined' ? window.location.hash : '';

  useEffect(() => {
    if (hash === '#privacy') {
      setShow('privacy');
    } else if (hash === '#terms') {
      setShow('terms');
    } else setShow('home');
  }, [hash]);

  return (
    <ThemeProvider theme={saasModernTheme}>
      <Fragment>
        <SEO title="Loyalty | Promotions | Insights" />

        <ResetCSS />
        <GlobalStyle />

        <ContentWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar setShow={setShow} show={show} />
            </DrawerProvider>
          </Sticky>

          {show === 'home' && (
            <>
              <BannerSection />
              <WorkingProcessSection />
              <InfoSection />
              <FeatureSection />
              <UpdateScreen />
              <PricingSection />
              <PartnerSection />
              <TestimonialSection />
              <FaqSection />
              <TrialSection />
              <Footer setShow={setShow} />
            </>
          )}
          {show === 'privacy' && (
            <>
              <PrivacySection />
            </>
          )}
          {show === 'terms' && (
            <>
              <TermsSection />
            </>
          )}
        </ContentWrapper>
      </Fragment>
    </ThemeProvider>
  );
};
