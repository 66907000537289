import React from 'react';
import { ListItem, Text } from '../../containers/SaasModern/Terms/terms.style';

export const FeeComponent = () => {
  return (
    <>
      <ListItem>Fee Changes</ListItem>
      <Text>
        <span>
          BLUEFROG VENTURES, in its sole discretion and at any time, may modify
          Subscription fees for the Subscriptions. Any Subscription fee change
          will become effective at the end of the then-current Billing Cycle.
        </span>
        <br />
        <br />
        <span>
          BLUEFROG VENTURES will provide you with a reasonable prior notice of
          any change in Subscription fees to give you an opportunity to
          terminate your Subscription before such change becomes effective.
        </span>
        <br />
        <br />
        <span>
          BLUEFROG VENTURES will provide you with a reasonable prior notice of
          any change in Subscription fees to give you an opportunity to
          terminate your Subscription before such change becomes effective.
        </span>
        <br />
        <br />
        <span>
          Your continued use of Service after Subscription fee change comes into
          effect constitutes your agreement to pay the modified Subscription fee
          amount.{' '}
        </span>
        <br />
        <br />
      </Text>
    </>
  );
};
