import React from 'react';
import { ListItem, Text } from '../../containers/SaasModern/Terms/terms.style';

export const ContactComponent = () => {
  return (
    <>
      <ListItem>Contact Us </ListItem>
      <Text>
        <span>
          Please send your feedback, comments, requests for technical support:
        </span>
        <br />
        <span>By email: support@estackk.com.</span>
        <br />
        <br />
      </Text>
    </>
  );
};
