import React from 'react';
import {
  ListItem,
  BoldSpan,
  Text,
} from '../../containers/SaasModern/Terms/terms.style';

export const GoverningComponent = () => {
  return (
    <>
      <ListItem>Governing Law </ListItem>
      <Text>
        <span>
          These Terms shall be governed and construed in accordance with the
          laws of [<BoldSpan>●</BoldSpan>] without regard to its conflict of law
          provisions.
        </span>
        <br />
        <br />
        <span>
          Our failure to enforce any right or provision of these Terms will not
          be considered a waiver of those rights. If any provision of these
          Terms is held to be invalid or unenforceable by a court, the remaining
          provisions of these Terms will remain in effect. These Terms
          constitute the entire agreement between us regarding our Service and
          supersede and replace any prior agreements we might have had between
          us regarding Service.
        </span>
        <br />
        <br />
      </Text>
    </>
  );
};
