import React from 'react';
import {
  ListItem,
  Text,
  BoldSpan,
} from '../../containers/SaasModern/Terms/terms.style';

export const TrialComponent = () => {
  return (
    <>
      <ListItem>Free Trial </ListItem>
      <Text>
        <span>
          BLUEFROG VENTURES may, at its sole discretion, offer a Subscription
          with a free trial for a limited period of time (
          <BoldSpan>“Free Trial”</BoldSpan>).
        </span>
        <br />
        <br />
        <span>
          You may be required to enter your billing information in order to sign
          up for Free Trial.
        </span>
        <br />
        <br />
        <span>
          If you do enter your billing information when signing up for Free
          Trial, you will not be charged by BLUEFROG VENTURES until Free Trial
          has expired. On the last day of Free Trial period, unless you
          cancelled your Subscription, you will be automatically charged the
          applicable Subscription fees for the type of Subscription you have
          selected.
        </span>
        <br />
        <br />
        <span>
          At any time and without notice, BLUEFROG VENTURES reserves the right
          to (i) modify Terms of Service of Free Trial offer, or (ii) cancel
          such Free Trial offer.
        </span>
        <br />
        <br />
      </Text>
    </>
  );
};
