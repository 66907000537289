import React from 'react';
import {
  ListItem,
  Text,
  BoldSpan,
} from '../../containers/SaasModern/Privacy/privacy.style';

export const ServiceComponent = () => {
  return (
    <>
      <ListItem>Service Providers</ListItem>
      <Text>
        <span>
          We may employ third party companies and individuals to facilitate our
          Service (<BoldSpan>“Service Providers”</BoldSpan>), provide Service on
          our behalf, perform Service-related services or assist us in analysing
          how our Service is used.
        </span>
        <br />
        <br />
        <span>
          These third parties have access to your Personal Data only to perform
          these tasks on our behalf and are obligated not to disclose or use it
          for any other purpose.
        </span>
        <br />
        <br />
      </Text>
    </>
  );
};
