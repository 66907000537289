import React from 'react';
import {
  ListItem,
  Text,
} from '../../containers/SaasModern/Privacy/privacy.style';

export const SecurityComponent = () => {
  return (
    <>
      <ListItem>Security of Data</ListItem>
      <Text>
        <span>
          The security of your data is important to us but remember that no
          method of transmission over the Internet or method of electronic
          storage is 100% secure. While we strive to use commercially acceptable
          means to protect your Personal Data, we cannot guarantee its absolute
          security.
        </span>
        <br />
        <br />
      </Text>
    </>
  );
};
