import React from 'react';
import { ListItem, Text } from '../../containers/SaasModern/Terms/terms.style';

export const AccountsComponent = () => {
  return (
    <>
      <ListItem>Accounts</ListItem>
      <Text>
        <span>
          When you create an account with us, you guarantee that you are above
          the age of 18, and that the information you provide us is accurate,
          complete, and current at all times. Inaccurate, incomplete, or
          obsolete information may result in the immediate termination of your
          account on Service.
        </span>
        <br />
        <br />
        <span>
          You are responsible for maintaining the confidentiality of your
          account and password, including but not limited to the restriction of
          access to your computer and/or account. You agree to accept
          responsibility for any and all activities or actions that occur under
          your account and/or password, whether your password is with our
          Service or a third-party service. You must notify us immediately upon
          becoming aware of any breach of security or unauthorized use of your
          account.
        </span>
        <br />
        <br />
        <span>
          You may not use as a username the name of another person or entity or
          that is not lawfully available for use, a name or trademark that is
          subject to any rights of another person or entity other than you,
          without appropriate authorization. You may not use as a username any
          name that is offensive, vulgar or obscene.
        </span>
        <br />
        <br />
        <span>
          We reserve the right to refuse service, terminate accounts, remove or
          edit content, or cancel orders in our sole discretion.
        </span>
        <br />
        <br />
      </Text>
    </>
  );
};
