import React from 'react';
import { ListItem, Text } from '../../containers/SaasModern/Terms/terms.style';

export const TerminationComponent = () => {
  return (
    <>
      <ListItem>Termination</ListItem>
      <Text>
        <span>
          We may terminate or suspend your account and bar access to Service
          immediately, without prior notice or liability, under our sole
          discretion, for any reason whatsoever and without limitation,
          including but not limited to a breach of Terms.
        </span>
        <br />
        <br />
        <span>
          If you wish to terminate your account, you may simply discontinue
          using Service.
        </span>
        <br />
        <br />
        <span>
          All provisions of Terms which by their nature should survive
          termination shall survive termination, including, without limitation,
          ownership provisions, warranty disclaimers, indemnity and limitations
          of liability.
        </span>
        <br />
        <br />
      </Text>
    </>
  );
};
