import React from 'react';
import { ListItem, Text } from '../../containers/SaasModern/Terms/terms.style';

export const RefundsComponent = () => {
  return (
    <>
      <ListItem>Refunds</ListItem>
      <Text>
        <span>
          Except when required by law, paid Subscription fees are
          non-refundable.{' '}
        </span>
        <br />
        <br />
      </Text>
    </>
  );
};
