import React from 'react';
import {
  ListItem,
  Text,
} from '../../containers/SaasModern/Privacy/privacy.style';

export const ChangesComponent = () => {
  return (
    <>
      <ListItem>Changes to This Privacy Policy</ListItem>
      <Text>
        <span>
          We may update our Privacy Policy from time to time. We will notify you
          of any changes by posting the new Privacy Policy on this page.
        </span>
        <br />
        <br />
        <span>
          We will let you know via email and/or a prominent notice on our
          Service, prior to the change becoming effective and update “effective
          date” at the top of this Privacy Policy.
        </span>
        <br />
        <br />
        <span>
          You are advised to review this Privacy Policy periodically for any
          changes. Changes to this Privacy Policy are effective when they are
          posted on this page.
        </span>
        <br />
        <br />
      </Text>
    </>
  );
};
