import React from 'react';
import {
  ListItem,
  Text,
  BoldSpan,
} from '../../containers/SaasModern/Terms/terms.style';

export const SubscriptionsComponent = () => {
  return (
    <>
      <ListItem>Subscriptions</ListItem>
      <Text>
        <span>
          Some parts of Service are billed on a subscription basis (
          <BoldSpan>“Subscription(s)”</BoldSpan>). You will be billed in advance
          on a recurring and periodic basis (
          <BoldSpan>“Billing Cycle”</BoldSpan>). Billing cycles are set either
          on a monthly or annual basis, depending on the type of subscription
          plan you select when purchasing a Subscription.
        </span>
        <br />
        <br />
        <span>
          At the end of each Billing Cycle, your Subscription will automatically
          renew under the exact same conditions unless you cancel it or BLUEFROG
          VENTURES cancels it. You may cancel your Subscription renewal either
          through your online account management page or by contacting BLUEFROG
          VENTURES customer support team.
        </span>
        <br />
        <br />
        <span>
          A valid payment method, including credit card or PayPal, is required
          to process the payment for your subscription. You shall
          provide BLUEFROG VENTURES with accurate and complete billing
          information including full name, address, state, zip code, telephone
          number, and a valid payment method information. By submitting such
          payment information, you automatically authorize BLUEFROG VENTURES to
          charge all Subscription fees incurred through your account to any such
          payment instruments.
        </span>
        <br />
        <br />
        <span>
          Should automatic billing fail to occur for any reason, BLUEFROG
          VENTURES will issue an electronic invoice indicating that you must
          proceed manually, within a certain deadline date, with the full
          payment corresponding to the billing period as indicated on the
          invoice.
        </span>
        <br />
        <br />
      </Text>
    </>
  );
};
