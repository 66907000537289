import React from 'react';
import { ListItem, Text } from '../../containers/SaasModern/Terms/terms.style';

export const AmendmentsComponent = () => {
  return (
    <>
      <ListItem>Amendments To Terms</ListItem>
      <Text>
        <span>
          We may amend Terms at any time by posting the amended terms on this
          site. It is your responsibility to review these Terms periodically.
        </span>
        <br />
        <br />
        <span>
          Your continued use of the Platform following the posting of revised
          Terms means that you accept and agree to the changes. You are expected
          to check this page frequently so you are aware of any changes, as they
          are binding on you.
        </span>
        <br />
        <br />
        <span>
          By continuing to access or use our Service after any revisions become
          effective, you agree to be bound by the revised terms. If you do not
          agree to the new terms, you are no longer authorized to use Service.
        </span>
        <br />
        <br />
      </Text>
    </>
  );
};
