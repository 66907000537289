import React from 'react';
import { ListItem, Text } from '../../containers/SaasModern/Terms/terms.style';
import {
  ListItemTwo,
  OlTwo,
} from '../../containers/SaasModern/Privacy/privacy.style';

export const ProhibitedComponent = () => {
  return (
    <>
      <ListItem>Prohibited Uses</ListItem>
      <Text>
        <span>
          You may use Service only for lawful purposes and in accordance with
          Terms. You agree not to use Service:
        </span>
        <br />
        <br />
        <OlTwo>
          <ListItemTwo>
            In any way that violates any applicable national or international
            law or regulation.
          </ListItemTwo>
          <br />
          <ListItemTwo>
            For the purpose of exploiting, harming, or attempting to exploit or
            harm minors in any way by exposing them to inappropriate content or
            otherwise.
          </ListItemTwo>
          <br />
          <ListItemTwo>
            To transmit, or procure the sending of, any advertising or
            promotional material, including any “junk mail”, “chain letter,”
            “spam,” or any other similar solicitation.
          </ListItemTwo>
          <br />
          <ListItemTwo>
            To impersonate or attempt to impersonate Company, a Company
            employee, another user, or any other person or entity.
          </ListItemTwo>
          <br />
          <ListItemTwo>
            In any way that infringes upon the rights of others, or in any way
            is illegal, threatening, fraudulent, or harmful, or in connection
            with any unlawful, illegal, fraudulent, or harmful purpose or
            activity.
          </ListItemTwo>
          <br />
          <ListItemTwo>
            To engage in any other conduct that restricts or inhibits anyone’s
            use or enjoyment of Service, or which, as determined by us, may harm
            or offend Company or users of Service or expose them to liability.
          </ListItemTwo>
          <br />
        </OlTwo>
        <span>Additionally, you agree not to:</span>
        <br />
        <br />
        <OlTwo>
          <ListItemTwo>
            Use Service in any manner that could disable, overburden, damage, or
            impair Service or interfere with any other party’s use of Service,
            including their ability to engage in real time activities through
            Service.
          </ListItemTwo>
          <br />
          <ListItemTwo>
            Use any robot, spider, or other automatic device, process, or means
            to access Service for any purpose, including monitoring or copying
            any of the material on Service.
          </ListItemTwo>
          <br />
          <ListItemTwo>
            Use any manual process to monitor or copy any of the material on
            Service or for any other unauthorized purpose without our prior
            written consent.
          </ListItemTwo>
          <br />
          <ListItemTwo>
            Use any device, software, or routine that interferes with the proper
            working of Service.
          </ListItemTwo>
          <br />
          <ListItemTwo>
            Introduce any viruses, trojan horses, worms, logic bombs, or other
            material which is malicious or technologically harmful.
          </ListItemTwo>
          <br />
          <ListItemTwo>
            Attempt to gain unauthorized access to, interfere with, damage, or
            disrupt any parts of Service, the server on which Service is stored,
            or any server, computer, or database connected to Service.
          </ListItemTwo>
          <br />
          <ListItemTwo>
            Attack Service via a denial-of-service attack or a distributed
            denial-of-service attack.
          </ListItemTwo>
          <br />
          <ListItemTwo>
            Take any action that may damage or falsify Company rating.
          </ListItemTwo>
          <br />
          <ListItemTwo>
            Otherwise attempt to interfere with the proper working of Service.
          </ListItemTwo>
          <br />
        </OlTwo>
      </Text>
    </>
  );
};
