import React from 'react';
import { ListItem, Text } from '../../containers/SaasModern/Terms/terms.style';

export const CommunicationsComponent = () => {
  return (
    <>
      <ListItem>Communications</ListItem>
      <Text>
        <span>
          By creating an Account on our Service, you agree to subscribe to
          newsletters, marketing or promotional materials and other information
          we may send. However, you may opt out of receiving any, or all, of
          these communications from us by following the unsubscribe link or by
          emailing at.
        </span>
        <br />
        <br />
      </Text>
    </>
  );
};
