import React from 'react';
import { ListItem, Text } from '../../containers/SaasModern/Terms/terms.style';

export const ChangesComponent = () => {
  return (
    <>
      <ListItem>Changes To Service</ListItem>
      <Text>
        <span>
          We reserve the right to withdraw or amend our Service, and any service
          or material we provide via Service, in our sole discretion without
          notice. We will not be liable if for any reason all or any part of
          Service is unavailable at any time or for any period. From time to
          time, we may restrict access to some parts of Service, or the entire
          Service, to users, including registered users.
        </span>
        <br />
        <br />
      </Text>
    </>
  );
};
