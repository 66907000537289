import React from 'react';
import { ListItem, Text } from '../../containers/SaasModern/Terms/terms.style';

export const LinksComponent = () => {
  return (
    <>
      <ListItem>Links To Other Web Sites</ListItem>
      <Text>
        <span>
          Our Service may contain links to third party web sites or services
          that are not owned or controlled by BLUEFROG VENTURES.
        </span>
        <br />
        <br />
        <span>
          BLUEFROG VENTURES has no control over, and assumes no responsibility
          for the content, privacy policies, or practices of any third party web
          sites or services. We do not warrant the offerings of any of these
          entities/individuals or their websites.
        </span>
        <br />
        <br />
        <span>
          YOU ACKNOWLEDGE AND AGREE THAT BLUEFROG VENTURES SHALL NOT BE
          RESPONSIBLE OR LIABLE, DIRECTLY OR INDIRECTLY, FOR ANY DAMAGE OR LOSS
          CAUSED OR ALLEGED TO BE CAUSED BY OR IN CONNECTION WITH USE OF OR
          RELIANCE ON ANY SUCH CONTENT, GOODS OR SERVICES AVAILABLE ON OR
          THROUGH ANY SUCH THIRD PARTY WEB SITES OR SERVICES.
        </span>
        <br />
        <br />
        <span>
          WE STRONGLY ADVISE YOU TO READ THE TERMS OF SERVICE AND PRIVACY
          POLICIES OF ANY THIRD PARTY WEB SITES OR SERVICES THAT YOU VISIT.
        </span>
        <br />
        <br />
      </Text>
    </>
  );
};
