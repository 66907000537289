import styled from 'styled-components';
import BgImage from 'common/src/assets/image/saasModern/map.png';

export const PartnerSectionWrapper = styled.section`
  padding: 120px 0;
  background-color: #f6fbf9;
  background-image: url(${BgImage});
  background-repeat: no-repeat;
  background-position: 25% center;

  @media (max-width: 990px) {
    padding: 100px 0;
  }
  @media (max-width: 767px) {
    padding: 80px 0;
  }
  @media (max-width: 575px) {
    padding: 60px 0;
  }
`;

export const QrCodeWrapper = styled.div`
  display: flex;
  justify-content: space-around;
`;

export const QrCodeBlock = styled.div`
  display: flex;
  flex-direction: column;
  height: 200px;
  justify-content: space-between;
`;
export const ImageWrapper = styled.div`
  position: relative;
`;
export const PlayImage = styled.img`
  &:hover {
    cursor: pointer;
  }
`;
