import React from 'react';
import {
  ListItem,
  Text,
  BoldSpan,
} from '../../containers/SaasModern/Terms/terms.style';

export const ErrorComponent = () => {
  return (
    <>
      <ListItem>Error Reporting and Feedback </ListItem>
      <Text>
        <span>
          You may provide us either directly at support@estackk.com or via third
          party sites and tools with information and feedback concerning errors,
          suggestions for improvements, ideas, problems, complaints, and other
          matters related to our Service (<BoldSpan>“Feedback”</BoldSpan>). You
          acknowledge and agree that: (i) you shall not retain, acquire or
          assert any intellectual property right or other right, title or
          interest in or to the Feedback; (ii) Company may have development
          ideas similar to the Feedback; (iii) Feedback does not contain
          confidential information or proprietary information from you or any
          third party; and (iv) Company is not under any obligation of
          confidentiality with respect to the Feedback. In the event the
          transfer of the ownership to the Feedback is not possible due to
          applicable mandatory laws, you grant Company and its affiliates an
          exclusive, transferable, irrevocable, free-of-charge, sub-licensable,
          unlimited and perpetual right to use (including copy, modify, create
          derivative works, publish, distribute and commercialize) Feedback in
          any manner and for any purpose.
        </span>
        <br />
        <br />
        <span>
          The third party sites and tools mentioned above include the following:
        </span>
        <br />
        <br />
        <BoldSpan>Bugsnag</BoldSpan>
        <br />
        <span>
          Bugsnag is a platform for monitoring and logging stability of
          applications provided by Bugsnag Inc. Please read their Privacy Policy
          here: https://docs.bugsnag.com/legal/privacy-policy/
        </span>
        <br />
        <br />
      </Text>
    </>
  );
};
