import React from 'react';
import {
  ListItem,
  Text,
} from '../../containers/SaasModern/Privacy/privacy.style';

export const TransferComponent = () => {
  return (
    <>
      <ListItem>Transfer of Data</ListItem>
      <Text>
        <span>
          Your information, including Personal Data, may be transferred to – and
          maintained on – computers located outside of your state, province,
          country or other governmental jurisdiction where the data protection
          laws may differ from those of your jurisdiction.
        </span>
        <br />
        <br />
        <span>
          If you are located outside United States and choose to provide
          information to us, please note that we transfer the data, including
          Personal Data, to United States and process it there.
        </span>
        <br />
        <br />
        <span>
          Your consent to this Privacy Policy followed by your submission of
          such information represents your agreement to that transfer.
        </span>
        <br />
        <br />
        <span>
          Bluefrog Ventures will take all the steps reasonably necessary to
          ensure that your data is treated securely and in accordance with this
          Privacy Policy and no transfer of your Personal Data will take place
          to an organisation or a country unless there are adequate controls in
          place including the security of your data and other personal
          information.
        </span>
        <br />
        <br />
      </Text>
    </>
  );
};
