import React from 'react';
import {
  ListItem,
  Text,
} from '../../containers/SaasModern/Privacy/privacy.style';

export const ContactComponent = () => {
  return (
    <>
      <ListItem>Contact Us</ListItem>
      <Text>
        <span>
          If you have any questions about this Privacy Policy, please contact
          us:
        </span>
        <br />
        <br />
        <span>By email: support@estackk.com.</span>
        <br />
        <br />
      </Text>
    </>
  );
};
