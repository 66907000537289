import React from 'react';
import {
  ListItem,
  Text,
  BoldSpan,
} from '../../containers/SaasModern/Privacy/privacy.style';

export const CICDComponent = () => {
  return (
    <>
      <ListItem>CI/CD tools</ListItem>
      <Text>
        <span>
          We may use third-party Service Providers to automate the development
          process of our Service.
        </span>
        <br />
        <br />
        <BoldSpan>GitHub</BoldSpan>
        <br />
        <br />
        <span>GitHub is provided by GitHub, Inc.</span>
        <br />
        <br />
        <span>
          GitHub is a development platform to host and review code, manage
          projects, and build software.
        </span>
        <br />
        <br />
        <span>
          For more information on what data GitHub collects for what purpose and
          how the protection of the data is ensured, please visit GitHub Privacy
          Policy page:
          <a href="https://help.github.com/en/articles/github-privacy-statement">
            https://help.github.com/en/articles/github-privacy-statement.
          </a>
        </span>
        <br />
        <br />
        <BoldSpan>Fastlane</BoldSpan>
        <br />
        <br />
        <span>Fastlane is provided by Google, Inc.</span>
        <br />
        <br />
        <span>
          Fastlane is a continuous delivery tool for iOS and Android apps.
        </span>
        <br />
        <br />
        <span>
          For more information on what data Fastlane collects for what purpose
          and how the protection of the data is ensured, please visit the Google
          Privacy Policy page: https://policies.google.com/privacy.
        </span>
        <br />
        <br />
      </Text>
    </>
  );
};
