import React from 'react';
import {
  ListItem,
  Text,
  BoldSpan,
} from '../../containers/SaasModern/Terms/terms.style';
import {
  ListItemTwo,
  OlTwo,
} from '../../containers/SaasModern/Privacy/privacy.style';

export const DmcaComponent = () => {
  return (
    <>
      <ListItem>
        DMCA Notice and Procedure for Copyright Infringement Claims
      </ListItem>
      <Text>
        <span>
          You may submit a notification pursuant to the Digital Millennium
          Copyright Act (DMCA) by providing our Copyright Agent with the
          following information in writing (see 17 U.S.C 512(c)(3) for further
          detail):
        </span>
        <br />
        <br />
        <OlTwo>
          <ListItemTwo>
            an electronic or physical signature of the person authorized to act
            on behalf of the owner of the copyright's interest;
          </ListItemTwo>
          <br />
          <ListItemTwo>
            a description of the copyrighted work that you claim has been
            infringed, including the URL (i.e., web page address) of the
            location where the copyrighted work exists or a copy of the
            copyrighted work;
          </ListItemTwo>
          <br />
          <ListItemTwo>
            identification of the URL or other specific location on Service
            where the material that you claim is infringing is located;
          </ListItemTwo>
          <br />
          <ListItemTwo>
            your address, telephone number, and email address;
          </ListItemTwo>
          <br />
          <ListItemTwo>
            a statement by you that you have a good faith belief that the
            disputed use is not authorized by the copyright owner, its agent, or
            the law;
          </ListItemTwo>
          <br />
          <ListItemTwo>
            a statement by you, made under penalty of perjury, that the above
            information in your notice is accurate and that you are the
            copyright owner or authorized to act on the copyright owner's
            behalf.
          </ListItemTwo>
          <br />
        </OlTwo>
        <span>
          You can contact our Copyright Agent via email at [
          <BoldSpan>●</BoldSpan>]
        </span>
        <br />
        <br />
      </Text>
    </>
  );
};
