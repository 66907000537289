import React from 'react';
import { TermsWrapper, Title, Ol, DateItem } from './terms.style';
import {
  IntroductionComponent,
  CommunicationsComponent,
  PurchasesComponent,
  ContestsComponent,
  SubscriptionsComponent,
  TrialComponent,
  FeeComponent,
  RefundsComponent,
  ContentComponent,
  ProhibitedComponent,
  AnalyticsComponent,
  MinorsComponent,
  AccountsComponent,
  IntellectualComponent,
  CopyrightComponent,
  DmcaComponent,
  ErrorComponent,
  LinksComponent,
  DisclaimerComponent,
  LimitationComponent,
  TerminationComponent,
  GoverningComponent,
  ChangesComponent,
  AmendmentsComponent,
  WaiverComponent,
  AcknowledgementComponent,
  ContactComponent,
} from '../../../components/Terms';

const TermsSection = () => {
  return (
    <TermsWrapper>
      <Title>TERMS OF SERVICE</Title>
      <DateItem>Last updated: 08/07/2020</DateItem>
      <Ol>
        <IntroductionComponent />
        <CommunicationsComponent />
        <PurchasesComponent />
        <ContestsComponent />
        <SubscriptionsComponent />
        <TrialComponent />
        <FeeComponent />
        <RefundsComponent />
        <ContentComponent />
        <ProhibitedComponent />
        <AnalyticsComponent />
        <MinorsComponent />
        <AccountsComponent />
        <IntellectualComponent />
        <CopyrightComponent />
        <DmcaComponent />
        <ErrorComponent />
        <LinksComponent />
        <DisclaimerComponent />
        <LimitationComponent />
        <TerminationComponent />
        <GoverningComponent />
        <ChangesComponent />
        <AmendmentsComponent />
        <WaiverComponent />
        <AcknowledgementComponent />
        <ContactComponent />
      </Ol>
    </TermsWrapper>
  );
};

export default TermsSection;
