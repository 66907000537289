import React from 'react';
import {
  ListItem,
  Text,
  ListItemTwo,
  OlTwo,
} from '../../containers/SaasModern/Privacy/privacy.style';

export const ProtectionCaliforniaPrivacyComponent = () => {
  return (
    <>
      <ListItem>
        Your Data Protection Rights under the California Privacy Protection Act
        (CalOPPA)
      </ListItem>
      <Text>
        <span>
          CalOPPA is the first state law in the nation to require commercial
          websites and online services to post a privacy policy. The law’s reach
          stretches well beyond California to require a person or company in the
          United States (and conceivable the world) that operates websites
          collecting personally identifiable information from California
          consumers to post a conspicuous privacy policy on its website stating
          exactly the information being collected and those individuals with
          whom it is being shared, and to comply with this policy. – See more
          at:
          https://consumercal.org/about-cfc/cfc-education-foundation/california-online-privacy-protection-act-caloppa-3/
        </span>
        <br />
        <br />
        <span>According to CalOPPA we agree to the following:</span>
        <br />
        <br />
        <OlTwo>
          <ListItemTwo>users can visit our site anonymously;</ListItemTwo>
          <br />
          <ListItemTwo>
            our Privacy Policy link includes the word “Privacy”, and can easily
            be found on the page specified above on the home page of our
            website;
          </ListItemTwo>
          <br />
          <ListItemTwo>
            users will be notified of any privacy policy changes on our Privacy
            Policy Page;
          </ListItemTwo>
          <br />
          <ListItemTwo>
            users are able to change their personal information by emailing us
            at support@estackk.com.
          </ListItemTwo>
          <br />
        </OlTwo>
        <span>Our Policy on “Do Not Track” Signals:</span>
        <br />
        <br />
        <span>
          We honor Do Not Track signals and do not track, plant cookies, or use
          advertising when a Do Not Track browser mechanism is in place. Do Not
          Track is a preference you can set in your web browser to inform
          websites that you do not want to be tracked.
        </span>
        <br />
        <br />
        <span>
          You can enable or disable Do Not Track by visiting the Preferences or
          Settings page of your web browser.
        </span>
        <br />
        <br />
      </Text>
    </>
  );
};
