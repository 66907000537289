import React from 'react';
import {
  ListItem,
  Text,
  BoldSpan,
} from '../../containers/SaasModern/Terms/terms.style';

export const PurchasesComponent = () => {
  return (
    <>
      <ListItem>Purchases</ListItem>
      <Text>
        <span>
          If you wish to purchase any product or service made available through
          Service (<BoldSpan>“Purchase”</BoldSpan>), you may be asked to supply
          certain information relevant to your Purchase including, without
          limitation, your credit card number, the expiration date of your
          credit card, your billing address, and your shipping information.
        </span>
        <br />
        <br />
        <span>
          You represent and warrant that: (i) you have the legal right to use
          any credit card(s) or other payment method(s) in connection with any
          Purchase; and that (ii) the information you supply to us is true,
          correct and complete.
        </span>
        <br />
        <br />
        <span>
          We may employ the use of third party services for the purpose of
          facilitating payment and the completion of Purchases. By submitting
          your information, you grant us the right to provide the information to
          these third parties subject to our Privacy Policy.
        </span>
        <br />
        <br />
        <span>
          We reserve the right to refuse or cancel your order at any time for
          reasons including but not limited to: product or service availability,
          errors in the description or price of the product or service, error in
          your order or other reasons.
        </span>
        <br />
        <br />
        <span>
          We reserve the right to refuse or cancel your order if fraud or an
          unauthorized or illegal transaction is suspected.{' '}
        </span>
        <br />
        <br />
      </Text>
    </>
  );
};
