import React from 'react';
import {
  ListItem,
  Text,
} from '../../containers/SaasModern/Privacy/privacy.style';

export const InformationComponent = () => {
  return (
    <>
      <ListItem>Information Collection and Use</ListItem>
      <Text>
        <span>
          We collect several different types of information for various purposes
          to provide and improve our Service to you.
        </span>
        <br />
        <br />
      </Text>
    </>
  );
};
