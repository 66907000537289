import React from 'react';
import { ListItem, Text } from '../../containers/SaasModern/Terms/terms.style';

export const MinorsComponent = () => {
  return (
    <>
      <ListItem>No Use By Minors </ListItem>
      <Text>
        <span>
          Service is intended only for access and use by individuals at least
          eighteen (18) years old. By accessing or using any of Company, you
          warrant and represent that you are at least eighteen (18) years of age
          and with the full authority, right, and capacity to enter into this
          agreement and abide by all of the terms and conditions of Terms. If
          you are not at least eighteen (18) years old, you are prohibited from
          both the access and usage of Service.
        </span>
        <br />
        <br />
      </Text>
    </>
  );
};
