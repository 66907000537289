import React from 'react';
import {
  ListItem,
  Text,
  BoldSpan,
} from '../../containers/SaasModern/Terms/terms.style';

export const IntroductionComponent = () => {
  return (
    <>
      <ListItem>Introduction</ListItem>
      <Text>
        <span>
          Welcome to{' '}
          <BoldSpan>BLUEFROG VENTURES, Inc. (“Company”, “we”, “our”, “us”)</BoldSpan>!
          As you have just clicked our Terms of Service, please pause, grab a
          cup of coffee and carefully read the following pages. It will take you
          approximately 20 minutes.
        </span>
        <br />
        <br />
        <span>
          These Terms of Service (
          <BoldSpan>“Terms”, “Terms of Service”</BoldSpan>) govern your use
          of our web pages located at https://estackk.com and our mobile
          application estackk , estackk POS (together or individually
          “Service”) operated by BLUEFROG VENTURES.
        </span>
        <br />
        <br />
        <span>
          Our Privacy Policy also governs your use of our Service and explains
          how we collect, safeguard and disclose information that results from
          your use of our web pages. Please read it here
          https://www.estackk.com/privacy.
        </span>
        <br />
        <br />
        <span>
          Your agreement with us includes these Terms and our Privacy Policy (
          <BoldSpan>“Agreements”</BoldSpan>). You acknowledge that you have read
          and understood Agreements, and agree to be bound of them.
        </span>
        <br />
        <br />
        <span>
          If you do not agree with (or cannot comply with) Agreements, then you
          may not use the Service, but please let us know by emailing at
          support@estackk.com so we can try to find a solution. These Terms
          apply to all visitors, users and others who wish to access or use
          Service.
        </span>
        <br />
        <br />
        <span>Thank you for being responsible.</span>
        <br />
        <br />
      </Text>
    </>
  );
};
