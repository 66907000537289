import React from 'react';
import {
  ListItem,
  Text,
  BoldSpan,
} from '../../containers/SaasModern/Terms/terms.style';

export const ContestsComponent = () => {
  return (
    <>
      <ListItem>Contests, Sweepstakes and Promotions </ListItem>
      <Text>
        <span>
          Any contests, sweepstakes or other promotions (collectively,{' '}
          <BoldSpan>“Promotions”</BoldSpan>) made available through Service may
          be governed by rules that are separate from these Terms of Service. If
          you participate in any Promotions, please review the applicable
          rules as well as our Privacy Policy. If the rules for a Promotion
          conflict with these Terms of Service, Promotion rules will apply.{' '}
        </span>
        <br />
        <br />
      </Text>
    </>
  );
};
