import React from 'react';
import { PolicyWrapper, Title, Ol, DateItem } from './privacy.style';
import {
  IntroductionComponent,
  DefinitionsComponent,
  InformationComponent,
  TypesComponent,
  UseComponent,
  RetentionComponent,
  TransferComponent,
  DisclosureComponent,
  SecurityComponent,
  ProtectionGeneralComponent,
  ProtectionCaliforniaPrivacyComponent,
  ProtectionCaliforniaConsumerComponent,
  ServiceComponent,
  AnalyticsComponent,
  CICDComponent,
  BehavioralComponent,
  PaymentsComponent,
  LinksComponent,
  ChildrensComponent,
  ChangesComponent,
  ContactComponent,
} from '../../../components/Privacy';

const PrivacySection = () => {
  return (
    <PolicyWrapper>
      <Title>PRIVACY POLICY</Title>
      <DateItem>Effective date: 04/22/2020</DateItem>
      <Ol>
        <IntroductionComponent />
        <DefinitionsComponent />
        <InformationComponent />
        <TypesComponent />
        <UseComponent />
        <RetentionComponent />
        <TransferComponent />
        <DisclosureComponent />
        <SecurityComponent />
        <ProtectionGeneralComponent />
        <ProtectionCaliforniaPrivacyComponent />
        <ProtectionCaliforniaConsumerComponent />
        <ServiceComponent />
        <AnalyticsComponent />
        <CICDComponent />
        <BehavioralComponent />
        <PaymentsComponent />
        <LinksComponent />
        <ChildrensComponent />
        <ChangesComponent />
        <ContactComponent />
      </Ol>
    </PolicyWrapper>
  );
};

export default PrivacySection;
