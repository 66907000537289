import React from 'react';
import {
  ListItem,
  Text,
  BoldSpan,
  ListItemTwo,
  OlTwo,
  ListItemThree,
} from '../../containers/SaasModern/Privacy/privacy.style';

export const ProtectionCaliforniaConsumerComponent = () => {
  return (
    <>
      <ListItem>
        Your Data Protection Rights under the California Consumer Privacy Act
        (CCPA)
      </ListItem>
      <Text>
        <span>
          If you are a California resident, you are entitled to learn what data
          we collect about you, ask to delete your data and not to sell (share)
          it. To exercise your data protection rights, you can make certain
          requests and ask us:
        </span>
        <br />
        <br />
        <OlTwo>
          <ListItemTwo>
            <BoldSpan>What personal information we have about you.</BoldSpan>
            <span>If you make this request, we will return to you:</span>
            <br />
            <br />
            <OlTwo>
              <ListItemThree>
                The categories of personal information we have collected about
                you.
              </ListItemThree>
              <br />
              <ListItemThree>
                The categories of sources from which we collect your personal
                information.
              </ListItemThree>
              <br />
              <ListItemThree>
                The business or commercial purpose for collecting or selling
                your personal information.
              </ListItemThree>
              <br />
              <ListItemThree>
                The categories of third parties with whom we share personal
                information.
              </ListItemThree>
              <br />
              <ListItemThree>
                The specific pieces of personal information we have collected
                about you.
              </ListItemThree>
              <br />
              <ListItemThree>
                A list of categories of personal information that we have sold,
                along with the category of any other company we sold it to. If
                we have not sold your personal information, we will inform you
                of that fact.
              </ListItemThree>
              <br />
              <ListItemThree>
                A list of categories of personal information that we have
                disclosed for a business purpose, along with the category of any
                other company we shared it with.
              </ListItemThree>
              <br />
            </OlTwo>
            <span>
              Please note, you are entitled to ask us to provide you with this
              information up to two times in a rolling twelve-month period. When
              you make this request, the information provided may be limited to
              the personal information we collected about you in the previous
              12 months.
            </span>
            <br />
            <br />
          </ListItemTwo>
          <ListItemTwo>
            <BoldSpan>To delete your personal information.</BoldSpan>
            <span>
              If you make this request, we will delete the personal information
              we hold about you as of the date of your request from our records
              and direct any service providers to do the same. In some cases,
              deletion may be accomplished through de-identification of the
              information. If you choose to delete your personal information,
              you may not be able to use certain functions that require your
              personal information to operate.{' '}
            </span>
          </ListItemTwo>
          <br />
          <ListItemTwo>
            <BoldSpan>To stop selling your personal information.</BoldSpan>
            <span>
              We do not sell your personal information for monetary
              consideration. However, under some circumstances, a transfer of
              personal information to a third party, or within our family of
              companies, without monetary consideration may be considered a
              “sale” under California law.
            </span>
            <br />
            <br />
            <span>
              If you submit a request to stop selling your personal information,
              we will stop making such transfers. If you are a California
              resident, to opt-out of the sale of your personal information,
              click “Do Not Sell My Personal Information” at the bottom of our
              home page to submit your request.
            </span>
            <br />
            <br />
            <span>
              Please note, if you ask us to delete or stop selling your data, it
              may impact your experience with us, and you may not be able to
              participate in certain programs or membership services which
              require the usage of your personal information to function. But in
              no circumstances, we will discriminate against you for exercising
              your rights.
            </span>
            <br />
            <br />
            <span>
              To exercise your California data protection rights described
              above, please send your request(s) by one of the following means:
            </span>
            <br />
            <br />
            <span>By email: support@estackk.com</span>
          </ListItemTwo>
          <br />
        </OlTwo>
        <span>
          Your data protection rights, described above, are covered by the CCPA,
          short for the California Consumer Privacy Act. To find out more, visit
          the official California Legislative Information website. The CCPA took
          effect on 01/01/2020.
        </span>
        <br />
        <br />
      </Text>
    </>
  );
};
