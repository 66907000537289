import React from 'react';
import {
  ListItem,
  Text,
  BoldSpan,
  ListItemTwo,
  OlTwo,
  ListItemThree,
} from '../../containers/SaasModern/Privacy/privacy.style';

export const DisclosureComponent = () => {
  return (
    <>
      <ListItem>Disclosure of Data</ListItem>
      <Text>
        <span>
          We may disclose personal information that we collect, or you provide:
        </span>
        <br />
        <br />
        <OlTwo>
          <ListItemTwo>
            <BoldSpan>Disclosure for Law Enforcement.</BoldSpan>
            <br />
            <span>
              Under certain circumstances, we may be required to disclose your
              Personal Data if required to do so by law or in response to valid
              requests by public authorities.
            </span>
          </ListItemTwo>
          <br />
          <ListItemTwo>
            <BoldSpan>Business Transaction.</BoldSpan>
            <br />
            <span>
              If we or our subsidiaries are involved in a merger, acquisition or
              asset sale, your Personal Data may be transferred.
            </span>
          </ListItemTwo>
          <br />
          <ListItemTwo>
            <BoldSpan>
              Other cases. We may disclose your information also:
            </BoldSpan>
            <br />
            <br />
            <OlTwo>
              <ListItemThree>
                <span>to our subsidiaries and affiliates;</span>
              </ListItemThree>
              <br />
              <ListItemThree>
                <span>to fulfill the purpose for which you provide it;</span>
              </ListItemThree>
              <br />
              <ListItemThree>
                <span>
                  for the purpose of including your company’s logo on our
                  website;
                </span>
              </ListItemThree>
              <br />
              <ListItemThree>
                <span>
                  for any other purpose disclosed by us when you provide the
                  information;
                </span>
              </ListItemThree>
              <br />
              <ListItemThree>
                <span>with your consent in any other cases;</span>
              </ListItemThree>
              <br />
              <ListItemThree>
                <span>
                  if we believe disclosure is necessary or appropriate to
                  protect the rights, property, or safety of the Company, our
                  customers, or others.
                </span>
              </ListItemThree>
              <br />
            </OlTwo>
          </ListItemTwo>
          <br />
        </OlTwo>
      </Text>
    </>
  );
};
