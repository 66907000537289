import React from 'react';
import {
  ListItem,
  Text,
  BoldSpan,
} from '../../containers/SaasModern/Privacy/privacy.style';

export const DefinitionsComponent = () => {
  return (
    <>
      <ListItem>Definitions</ListItem>
      <Text>
        <span>
          <BoldSpan>SERVICE</BoldSpan> means the https://estackk.com website and
          estackk & estackk POS mobile application operated by Bluefrog
          Ventures.
        </span>
        <br />
        <br />
        <span>
          <BoldSpan>PERSONAL DATA</BoldSpan> means data about a living
          individual who can be identified from those data (or from those and
          other information either in our possession or likely to come into our
          possession).
        </span>
        <br />
        <br />
        <span>
          <BoldSpan>USAGE DATA</BoldSpan> is data collected automatically either
          generated by the use of Service or from Service infrastructure itself
          (for example, the duration of a page visit).
        </span>
        <br />
        <br />
        <span>
          <BoldSpan>COOKIES</BoldSpan> are small files stored on your device
          (computer or mobile device).
        </span>
        <br />
        <br />
        <span>
          <BoldSpan>DATA CONTROLLER</BoldSpan> means a natural or legal person
          who (either alone or jointly or in common with other persons)
          determines the purposes for which and the manner in which any personal
          data are, or are to be, processed. For the purpose of this Privacy
          Policy, we are a Data Controller of your data.
        </span>
        <br />
        <br />
        <span>
          <BoldSpan>DATA PROCESSORS (OR SERVICE PROVIDERS)</BoldSpan> means any
          natural or legal person who processes the data on behalf of the Data
          Controller. We may use the services of various Service Providers in
          order to process your data more effectively.
        </span>
        <br />
        <br />
        <span>
          <BoldSpan>DATA SUBJECT</BoldSpan> is any living individual who is the
          subject of Personal Data.
        </span>
        <br />
        <br />
        <span>
          <BoldSpan>THE USER</BoldSpan> is the individual using our Service. The
          User corresponds to the Data Subject, who is the subject of Personal
          Data.
        </span>
        <br />
        <br />
      </Text>
    </>
  );
};
