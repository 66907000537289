import React from 'react';
import {
  ListItem,
  Text,
  BoldSpan,
} from '../../containers/SaasModern/Terms/terms.style';

export const CopyrightComponent = () => {
  return (
    <>
      <ListItem>Copyright Policy</ListItem>
      <Text>
        <span>
          We respect the intellectual property rights of others. It is our
          policy to respond to any claim that Content posted on Service
          infringes on the copyright or other intellectual property rights (
          <BoldSpan>“Infringement”</BoldSpan>) of any person or entity.
        </span>
        <br />
        <br />
        <span>
          If you are a copyright owner, or authorized on behalf of one, and you
          believe that the copyrighted work has been copied in a way that
          constitutes copyright infringement, please submit your claim via email
          to [<BoldSpan>●</BoldSpan>], with the subject line: “Copyright
          Infringement” and include in your claim a detailed description of the
          alleged Infringement as detailed below, under “DMCA Notice and
          Procedure for Copyright Infringement Claims”
        </span>
        <br />
        <br />
        <span>
          You may be held accountable for damages (including costs and
          attorneys' fees) for misrepresentation or bad-faith claims on the
          infringement of any Content found on and/or through Service on your
          copyright.
        </span>
        <br />
        <br />
      </Text>
    </>
  );
};
