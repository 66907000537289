import React from 'react';
import { ListItem, Text } from '../../containers/SaasModern/Terms/terms.style';

export const AcknowledgementComponent = () => {
  return (
    <>
      <ListItem>Acknowledgement</ListItem>
      <Text>
        <span>
          BY USING SERVICE OR OTHER SERVICES PROVIDED BY US, YOU ACKNOWLEDGE
          THAT YOU HAVE READ THESE TERMS OF SERVICE AND AGREE TO BE BOUND BY
          THEM.
        </span>
        <br />
        <br />
      </Text>
    </>
  );
};
