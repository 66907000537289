import React from 'react';
import { ListItem, Text } from '../../containers/SaasModern/Terms/terms.style';

export const IntellectualComponent = () => {
  return (
    <>
      <ListItem>Intellectual Property </ListItem>
      <Text>
        <span>
          Service and its original content (excluding Content provided by
          users), features and functionality are and will remain the exclusive
          property of BLUEFROG VENTURES and its licensors. Service is protected
          by copyright, trademark, and other laws of the United States and
          foreign countries. Our trademarks and trade dress may not be used in
          connection with any product or service without the prior written
          consent of BLUEFROG VENTURES.
        </span>
        <br />
        <br />
      </Text>
    </>
  );
};
