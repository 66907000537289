import React from 'react';
import {
  ListItem,
  Text,
} from '../../containers/SaasModern/Privacy/privacy.style';

export const LinksComponent = () => {
  return (
    <>
      <ListItem>Links to Other Sites</ListItem>
      <Text>
        <span>
          Our Service may contain links to other sites that are not operated by
          us. If you click a third party link, you will be directed to that
          third party's site. We strongly advise you to review the Privacy
          Policy of every site you visit.
        </span>
        <br />
        <br />
        <span>
          We have no control over and assume no responsibility for the content,
          privacy policies or practices of any third party sites or services.
        </span>
        <br />
        <br />
      </Text>
    </>
  );
};
