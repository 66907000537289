import React from 'react';
import {
  ListItem,
  Text,
  BoldSpan,
} from '../../containers/SaasModern/Privacy/privacy.style';

export const AnalyticsComponent = () => {
  return (
    <>
      <ListItem>Analytics</ListItem>
      <Text>
        <span>
          We may use third-party Service Providers to monitor and analyze the
          use of our Service.
        </span>
        <br />
        <br />
        <BoldSpan>Google Analytics</BoldSpan>
        <br />
        <span>
          Google Analytics is a web analytics service offered by Google that
          tracks and reports website traffic. Google uses the data collected to
          track and monitor the use of our Service. This data is shared with
          other Google services. Google may use the collected data to
          contextualise and personalise the ads of its own advertising network.
        </span>
        <br />
        <br />
        <span>
          For more information on the privacy practices of Google, please visit
          the Google Privacy Terms web page:
          https://policies.google.com/privacy?hl=en
        </span>
        <br />
        <br />
        <span>
          We also encourage you to review the Google's policy for safeguarding
          your data: https://support.google.com/analytics/answer/6004245.
        </span>
        <br />
        <br />
        <BoldSpan>Mixpanel</BoldSpan>
        <br />
        <span>Mixpanel is provided by Mixpanel Inc.</span>
        <br />
        <br />
        <span>
          You can prevent Mixpanel from using your information for analytics
          purposes by opting-out. To opt-out of Mixpanel service, please visit
          this page: https://mixpanel.com/optout/
        </span>
        <br />
        <br />
        <span>
          For more information on what type of information Mixpanel collects,
          please visit the Terms of Use page of Mixpanel:
          https://mixpanel.com/terms/
        </span>
        <br />
        <br />
      </Text>
    </>
  );
};
